:root {
  /* dark shades of primary color*/
  /* --clr-primary-1: hsl(43, 86%, 17%); */

  --color: #0a0c10;
  /* 256 */
  --clr-primary-1: hsl(245, 91%, 17%);
  --clr-primary-2: hsl(245, 84%, 25%);
  --clr-primary-3: hsl(245, 81%, 29%);
  --clr-primary-4: hsl(245, 77%, 34%);
  --clr-primary-5: hsl(245, 62%, 45%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(245, 57%, 50%);
  --clr-primary-7: hsl(245, 65%, 59%);
  --clr-primary-8: hsl(245, 80%, 74%);
  --clr-primary-9: hsl(245, 94%, 87%);
  --clr-primary-10: hsl(245, 87%, 94%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(206, 33%, 96%);
  --clr-green-dark: hsl(125, 67%, 35%);
  --clr-green-light: hsl(134, 41%, 88%);
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-white: #fff;
  --clr-black: #0a0c10;
  --ff-primary: "Roboto", sans-serif;
  --ff-secondary: "Open Sans", sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 700px;
}

h1,
h2,
h3,
h4,
h5 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--ff-primary);
  text-align: justify;
}


.zoom-medium:hover{
  transform: scale(1.2)
  
}

.footer-widget h4
 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--clr-grey-10);
  text-align: justify;
}
p {
  margin-top: 20px;
  text-align: justify;
  /* text-justify: inter-word; */
}

.link-inline-padding{
  padding: 5px;
  margin-left: 20px;
}

ul{
  text-align: left;
}

.recent-post ul{
  align-items: center;
  margin-top: 70px;
}

.sidebar-section {
  align-items: center;
  margin-top: 70px;
}

.content-align{
  align-items: center;
}

div {
  text-align: center;
  text-justify: inter-word;
}

.not-found{
    background: url("https: //images.pexels.com/photos/365637/pexels-photo-365637.jpeg?cs=srgb&dl=pexels-aphiwat-chuangchoem-365637.jpg&fm=jpg");
    background-color: #cccccc;
    max-width: 85vw;
    height: "800px";
}

.custom-nav {
  font-size: small;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.3);

}

.custom-nav ul.dropdown-menu {
  font-size: small;
  text-align: left;
}

.custom-devider {
  height: 5px;
  background: rgb(213, 167, 147);
  background: linear-gradient(90deg, rgba(213, 167, 147, 1) 0%, rgba(77, 4, 57, 1) 17%, rgba(9, 9, 121, 1) 35%, rgba(6, 68, 160, 1) 54%, rgba(76, 106, 54, 1) 75%, rgba(0, 212, 255, 1) 100%);
}

.main-banner {
  padding-top: 150px;
  padding-bottom: 100px;
  width: 100%;
  height: 340px;
  background-image: url("https://images.pexels.com/photos/313782/pexels-photo-313782.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  color: white;
  align-content: flex-end;

}

.feature-holder {
  text-align: center;
  margin-bottom: 25px;
  justify-content: left;
}

.features {
  padding: 35px;
  padding-bottom: 20px;
  background-color: #f3f3f3;
}

.feature-holder {
  text-align: center;
}

.feature-holder h3 {
  text-align: center;
  font-size: 1.2rem;
}

section.online-courses,
.classroom-training,
.page-content {
  padding-top: 35px;
  padding-bottom: 35px;
}

.online-courses-text-widget,
.classroom-training-text-widget {
  text-align: center;
  font-size: 0.25 rem;
}

.online-courses-text-widget h2,
.classroom-training-text-widget h2 {
  font-size: 1.2 rem;
}

.online-courses-text-widget p,
.classroom-training-text-widget p {
  font-size: 14px;
}

.online-course-card {
  text-align: center;
  margin-bottom: 25px;
}

.online-course-card .card-title {
  font-size: 1em;
  line-height: 20px;
}

.card.online-course-card .card-text {
  font-size: 14px;
}

.classroom-training {
  background-color: whitesmoke;
}


footer.main-footer {
  background-color: #292929;
  color: white;
  padding-top: 35px;
  padding-bottom: 35px;
}

aside.footer-widget {
  margin-bottom: 25px;
}

aside.footer-widget h5 {
  font-size: 16px;
}

aside.footer-widget ul {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

aside.footer-widget ul li a {
  text-decoration: none;
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 5px;
}

address h2 {
  font-size: 20px;
}

address p {
  font-size: 14px;
}

section.copy {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: black;
  color: white;
  font-size: 14px;
  text-align: center;
}

section.copy p {
  margin: 0px;
}


.error-page {
  background: var(--clr-grey-10);
  display: grid;
  place-items: center;
  text-align: center;
}

.error-page {
  background: var(--clr-primary-10);
  padding: 2rem;
}

.error-page span {
  font-size: 10rem;
  color: var(--clr-black);
}

.error-page h3 {
  margin-bottom: 2rem;
  padding: 0 2rem;
}

.sign-up-form-sidebar .card-title {
  font-size: 1.2em;
}

.sign-up-form-sidebar .card-text {
  font-size: 14px;
}

.course-list-item {
  font-size: small;
  padding-top: 20px;
  color: black;
}

.course-list-item h5 {
  font-size: 1.2em;
}


/*
===============
Pros & Cons
===============
*/

.i2-pros-cons-wrapper .i2-pros-title {
  background-color: #00bf08 !important;
  margin-top: 20px;
}

.i2-pros-cons-wrapper .i2-cons-title {
  background-color: #bf000a !important;
}

.icon-thumbs-o-up:before {
  content: "\68";
}

.i2-pros-cons-main-wrapper .i2-pros-cons-wrapper .i2-pros .i2-cons-title,
.i2-pros-cons-main-wrapper .i2-pros-cons-wrapper .i2-cons .i2-cons-title {
  background-color: #bf000a;
  margin-top: 25px;
}

.i2-pros-cons-main-wrapper .i2-pros-cons-wrapper .i2-pros .i2-pros-title,
.i2-pros-cons-main-wrapper .i2-pros-cons-wrapper .i2-pros .i2-cons-title,
.i2-pros-cons-main-wrapper .i2-pros-cons-wrapper .i2-cons .i2-pros-title,
.i2-pros-cons-main-wrapper .i2-pros-cons-wrapper .i2-cons .i2-cons-title {
  color: #fff;
  padding: 5px 15px 5px;
  margin: 0px;
  font-size: 22px;
  display: block;
  margin-top: 25px;
}

.i2-pros-cons-wrapper .i2-cons-title {
  background-color: #bf000a !important;
}

.i2-pros-cons-main-wrapper .i2-pros-cons-wrapper .i2-pros .section ul li,
.i2-pros-cons-main-wrapper .i2-pros-cons-wrapper .i2-cons .section ul li {
  padding-left: 30px;
  list-style: outside none none !important;
  position: relative;
  margin-top: 20px;
  text-align: justify;
}

.thumsUp {
  color: "green";
}

.thumsDn {
  color: "red";
}

a.fasc-button.rounded.fasc-size-medium,
.fasc-rounded-medium.fasc-size-medium {
  border-radius: 9px;
  width: 300px;
  height: 40px;
  padding-right: 20px;
  padding-left: 20px;
  font: 1em sans-serif;
  align-content: center;
  background-color: #c74337;
}